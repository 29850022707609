// Use this file to override default variables
:root {
  --ion-color-primary: #1F497D;
  --ion-color-primary-rgb: 31,73,125;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1b406e;
  --ion-color-primary-tint: #355b8a;

  --ion-color-secondary: #00AEEF;
  --ion-color-secondary-rgb: 0,174,239;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #0099d2;
  --ion-color-secondary-tint: #1ab6f1;

  --uoa-text: #4A4A4C;
}