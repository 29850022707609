/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. 
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
body{
  color: var(--uoa-text);
}
@import '~@uoa/styles/css';
.modal{
  overflow-y: auto;
  --width: 700px;
  --height: 350px;
}